import config from 'config';
import { Component, Watch } from '@zento-lib/components';
import { decodePhpHtmlString } from '@zento-lib/util/html';

import { Header } from '../../Header';
import { Menu } from '../../../Menu/Menu';
import { Wishlist } from '../../../Wishlist/Wishlist';
import { Cart } from '../../../Cart/Cart';
import { MyAccount } from '../../../../molecule/MyAccount/MyAccount';
import { Account } from '../../../../molecule/MyAccount/Account';
import { SearchItem } from '../../../../molecule/Search/SearchItem';
import { LanguageSwitcher } from '../../../../molecule/LanguageSwitcher/LanguageSwitcher';
import { Logo } from '../../../../atom/Logo/Logo';
import { HeaderItem } from '../../../../atom/HeaderItem/HeaderItem';
import { InnerHTML } from '../../../../atom/InnerHTML';

import style from './style.scss';

/**
 * Header 2
 *
 * Header 2 allowing to show header items order and position.
 **/
@Component({})
export class Header2 extends Header {
  private static cmsPagePathPrefix = '/i/';
  private static stickyThreshold =
    'menuStickyThreshold' in config.zento.theme.menu ? config.zento.theme.menu?.menuStickyThreshold : 600;

  @Watch('isDesktop')
  getIsDesktop() {
    if (!this.isDesktop) {
      if (this.isSidebarOpen) {
        this.$store.commit('ui/setOverlay', true);
      }
    } else if (this.elementsState) {
      this.$store.commit('ui/setOverlay', false);
    }
  }

  data() {
    return {
      searchVisible: false,
      stickyVisible: false,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      headerHeight: 0,
    };
  }

  async beforeMount() {
    this.onBroadcast('search/is-visible', (searchVisible: boolean) => {
      this.$data.searchVisible = searchVisible;
    });

    if (!this.isServer) {
      this.onScroll = this.onScroll.bind(this);
      window.addEventListener('scroll', this.onScroll, { passive: true });

      setInterval(() => {
        if (this.$data.isScrolling) {
          this.hasScrolled();
          this.$data.isScrolling = false;
        }
      }, 50);
    }
  }

  async mounted() {
    this.$data.headerHeight = this.$el ? this.$el.clientHeight : 210;
  }

  beforeDestroy() {
    this.offBroadcast('search/is-visible');

    if (!this.isServer) {
      window.removeEventListener('scroll', this.onScroll);
    }
  }

  render() {
    const wishlistCount = this.$store.state.wishlist.items.length ?? null;

    return (
      <header
        class={{
          [style.header]: true,
          [style.headerAbsolute]:
            this.$route.path === '/' ||
            (this.$route.meta && this.$route.meta.originalPath === '/') ||
            this.$route.path.indexOf(Header2.cmsPagePathPrefix) > -1,
          [style.drawerActive]: !this.isDesktop && this.isOverlayActive,
          [style.activeSearch]: this.activeSearch.searchState,
          [style.overlayActive]:
            this.isOverlayActive &&
            !this.isSidebarOpen &&
            !this.isDrawerFilter &&
            this.elementsState &&
            !this.activeSearch.searchState,
        }}
        key='main-header'>
        <div class={style.pageContainerWrapper}>
          {this.$route.path === '/' ||
          (this.$route.meta && this.$route.meta.originalPath === '/') ||
          this.$route.path.indexOf(Header2.cmsPagePathPrefix) > -1 ? (
            <div class={style.gradient} key='header-gradient' />
          ) : null}

          {this.uspStore.uspsAllPages
            ? this.uspStore.uspsAllPages.map((item) => {
                return (
                  <div class={[style.pageContainerWrapper, style.topContainer]} key='usp-data'>
                    <InnerHTML contents={decodePhpHtmlString(item.content)} class={style.container} />
                  </div>
                );
              })
            : null}

          <div
            class={{
              [style.container]: true,
              [style.elementsContainer]: this.$data.scrollTop > Header2.stickyThreshold,
              [style.isVisible]: this.$data.stickyVisible,
            }}
            key='top-line'>
            <Menu
              tree={this.tree}
              isOpened={this.isSidebarOpen}
              icon='hamburger-white' // TODO: change icon after fix
              class={{
                [style.menuTree]: true,
                [style.menuWrapper]:
                  this.$route.path === '/' || (this.$route.meta && this.$route.meta.originalPath === '/'),
              }}
              key='main-menu'
            />

            <div class={style.languageSwitcher}>
              {this.extended.$config.storeViews.multistore && this.isDesktop ? <LanguageSwitcher /> : null}
            </div>

            <Logo
              class={{
                [style.logo]: true,
                // Temporary class added to accommodate client needs
                [style.hideLogo]:
                  config.zento.theme.header.hideLogo &&
                  (this.$route.path === '/' || (this.$route.meta && this.$route.meta.originalPath === '/')),
              }}
              key='logo'
            />

            <div class={style.blocks} key='right-items'>
              <div class={style.blocksItems}>
                <SearchItem
                  searchPlaceholder={{ id: Header.T.searchPlaceholder }}
                  searchButton={{ id: Header.T.searchButton }}
                  searchProducts={{ id: Header.T.searchProducts }}
                  searchSeeMore={{ id: Header.T.searchSeeMore }}
                  searchLabel={{ id: Header.T.searchLabel }}
                  searchCancel={{ id: Header.T.searchCancel }}
                  searchClear={{ id: Header.T.searchClear }}
                  icon='search-white' // TODO: change icon after fix
                  class={[style.search, style.blockItem]}
                  key='search'
                />

                <Account
                  headerItemLabel={{ id: this.isDesktop ? Header.T.labelAccount : '' }}
                  icon='account-white' // TODO: change icon after fix
                  class={style.blockItem}
                  key='account'
                />

                <HeaderItem
                  label={{ id: this.isDesktop ? Header.T.labelWishlist : '' }}
                  icon='wishlist-white' // TODO: change icon after fix
                  onPress={this.callOpenWishlist}
                  count={wishlistCount}
                  testId='wishlist-icon'
                  class={style.blockItem}
                  key='wishlist-icon'
                />

                <HeaderItem
                  label={{ id: this.isDesktop ? Header.T.labelCart : '' }}
                  icon='cart-white' // TODO: change icon after fix
                  onPress={this.callOpenCart}
                  count={this.countCartProducts}
                  testId='cart-icon'
                  class={style.blockItem}
                  key='cart-icon'
                />
              </div>
            </div>
          </div>
        </div>

        <MyAccount
          myAccountLogout={{ id: Header.T.myAccountLogout }}
          myAccountTitle={{ id: Header.T.myAccountTitle }}
          myAccountUserName={{ id: Header.T.myAccountUserName }}
          key='my-account'
        />

        <Wishlist isOpened={this.isWishlistOpen} key='wishlist' />

        <Cart isOpened={this.isMicrocartOpen} key='cart' />
      </header>
    );
  }

  private hasScrolled() {
    this.$data.scrollTop = window.scrollY;

    if (this.$data.scrollTop > this.$data.lastScrollTop && this.$data.scrollTop > this.$data.headerHeight) {
      this.$data.stickyVisible = false;
    } else {
      this.$data.stickyVisible = true;
    }

    this.$data.lastScrollTop = this.$data.scrollTop;
  }

  private onScroll() {
    this.$data.isScrolling = true;
  }
}
